import React, { useState } from 'react';
import axios from 'axios';
import s from './login.module.css';
import { NavLink } from 'react-router-dom';
import App from '../App';
import state from './state';
import FogotPass from './fogotPass';
import indexedDBConnection from './indexedDBConnection';



function LoginForm() {
 

  const [userId, setUserId] = useState('');
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sendPass, setSendPass] = useState(false);

  const handleLoginChange = (e) => {
    setLogin(e.target.value.trim());
  };

  const handlePassChange = (e) => {
    setPass(e.target.value.trim());
  };

  const handleLoginSuccess = (userId) => {
    setUserId(userId.trim());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Перевірка на заповненість полів
    if (!login || !pass) {
      setErrorMessage('Ви ввели не всі дані для авторизації. Будь ласка, заповніть усі поля форми.');
      return;
    }
 
    // Кодування паролю в форматі base64
  const encodedPassword = btoa(pass);


    // Відправлення запиту на сервер для авторизації
    axios
    .post(state.api.login, { login, pass: encodedPassword,  })
    .then((response) => {
      if (response.data.userProfile) {
        // Якщо авторизація успішна, встановлюємо `state.globalId` і переадресовуємо користувача 
        let userProfile 
        try{
         userProfile = JSON.parse(response.data.userProfile)
        }catch{
          userProfile = response.data.userProfile
        }
                
        indexedDBConnection.saveData('userProfile', userProfile);
       
        state.userProfile = userProfile
       
        state.tempUserProfile =  Object.assign({},userProfile )
        state.globalId = state.userProfile ? parseInt(state.userProfile.id) : null;
        state.stateUpdate(); // Отримуємо дані з БД
        handleLoginSuccess(response.data.userProfile.userId);
      }
    
   else {
          setErrorMessage('Неправильний логін або пароль. Будь ласка, спробуйте знову.');
        }
      })
      .catch((error) => {
        console.error('Помилка запиту до сервера:', error.data);
        setErrorMessage(
          'Помилка під час авторизації. Будь ласка, спробуйте пізніше.'
        );
      });
  };

  
  return (<div>
    {state.globalId ? (
        <App/>
      ) : ( 
    <div className={s.main}>
     
       
          <h2>Авторизація</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Логін:</label><br />
              <input
                type="text"
                value={login}
                onChange={handleLoginChange}
                required
              />
            </div>
            <div>
              <label>Пароль:</label><br />
              <input
                type="password"
                value={pass}
                onChange={handlePassChange}
                required
              />
            </div>
          
            
            <div>
              <button type="submit">Увійти</button>
              <NavLink  to='/registration'><button>Зареєструватись</button></NavLink>
           <div className={s.fogotPass} onClick={()=>{setSendPass(true)}}> Забули пароль чи логін? </div>    
            </div>
            {sendPass?<FogotPass setErrorMessage={setErrorMessage}/>:null}

            {errorMessage && <p className={s.errorMessage} onClick={()=>{setErrorMessage('')}}>
              {errorMessage}</p>}
          </form>
        
    </div>
    
      )}
      
    </div>
  );
}

export default LoginForm;
